import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This exercise is about learning to shift your weight from one leg to the other.`}</p>
    <h2 {...{
      "id": "setup"
    }}>{`Setup`}</h2>
    <p>{`Stand upright with your feet hip-width to shoulder-width apart, and your toes pointing straight forward, like you do for `}<a parentName="p" {...{
        "href": "/lessons/hug-a-tree/"
      }}>{`Hug a Tree`}</a></p>
    <p>{`Your weight should be evenly split between your feet: 50% on one side, 50% on the other.`}</p>
    <p>{`This is called a “horse stance”.`}</p>
    <p>{`Relax your arms at your sides.`}</p>
    <h2 {...{
      "id": "exercise"
    }}>{`Exercise`}</h2>
    <p>{`Keeping an upright posture, slowly sit your weight down into your left foot while picking up your right foot.`}</p>
    <p>{`As you settle to the left, you first pick up the heel of the right side, then the ball of the foot, then the toes.`}</p>
    <p>{`Then put the right foot back down, first the toes, then the ball, then the heel. Return to standing with your weight on both feet.`}</p>
    <p>{`Now, sit your weight down into your right foot, while picking up the left foot in the same way.`}</p>
    <p>{`Do each side 10 times.`}</p>
    <h2 {...{
      "id": "modifications"
    }}>{`Modifications`}</h2>
    <p>{`If your legs aren’t strong enough to support all of your weight, you don’t have to pick your foot up off the ground. Just lift the heel, shifting 60% or 70% onto one leg. Then return to center and sit to the other side.`}</p>
    <p>{`If your balance isn’t good enough - hold on to something while doing this exercise.`}</p>
    <h2 {...{
      "id": "common-mistakes"
    }}>{`Common Mistakes`}</h2>
    <p>{`Don’t lean your body left and right as you shift your weight. You want to “settle” the weight downward into your foot, not counterweight picking up your leg by leaning to the other side.`}</p>
    <p>{`Don’t twist your knees as you sit into your leg. Keep the knee in line with the toe. It’s okay for the hips to turn slightly to the side that you’re picking up, as long as it’s not causing torque on the knee.`}</p>
    <p><a parentName="p" {...{
        "href": "/lessons/lesson-1/"
      }}>{`Back to Lesson 1`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      